
import { defineComponent, computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable'
import getBienQuery from '@/graphql/bien/get.gql'
import { helpers } from '@/helpers'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'


// My components
import Navigation from '@/components/Navigation.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CalendarDisplay  from '@/components/CalendarDisplay.vue'

export default defineComponent({
  name: 'Livret_selection',
  components: {
    Navigation,
    Header,
    Footer,
    
    CalendarDisplay
  },
  data() {
    return {
      selectedBien: 0,
      localBiens: {
        'sainte-maxime': {
          type: 'apartment',
          spa_images: [
            "/uploads/St_Maxime_20_b8339d7854.jpg",
            "/uploads/St_Maxime_10_7c5561090e.jpg"
          ],
          activities_images: [
            "/uploads/St_Maxime_15_ee7548d1aa.jpg",
            "/uploads/St_Maxime_04_5c7f20662f.jpg",
            "/uploads/St_Maxime_07_df6644d50d.jpg",
            "/uploads/St_Maxime_14_b311ba569d.jpg"
          ],
          room_images: [
            "/uploads/St_Maxime_17_d50fde0576.jpg",
            "/uploads/St_Maxime_16_fbcb29b993.jpg",
            "/uploads/St_Maxime_21_d282e0464e.jpg",
            "/uploads/St_Maxime_01_3116a9dccd.jpg"
          ],
          equipment_images: [
            "/uploads/St_Maxime_26_cd17262c31.jpg",
            "/uploads/St_Maxime_15_ee7548d1aa.jpg",
            "/uploads/St_Maxime_22_2d32bfaf6b.jpg",
            "/uploads/St_Maxime_18_a88ceba6af.jpg"
          ],
          size: 80,
          nbRooms: 2,
          nbBeds: {
            double: 2,
            simple: 0,
            couch: 1
          }
        },
      }
    }
  },
  setup() {
    const route = useRoute()
    const { result } = useQuery(getBienQuery, {link: route.params.bien_name})
    const biens = useResult(result, null, data => data.biens)
    const { locale } = useI18n({ useScope: 'global' })

    const langIs = (lang: string) => {
      if(locale.value == lang)
        return true
      else
        return false
    }

    return { 
      result,
      helpers,
      langIs
   }
  },
  methods: {
  },
});
