
import { defineComponent } from 'vue'

// My components
import Navigation from '@/components/Navigation.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'Bien',
  inject: ['EnvironmentVariables'],
  props: ['bien_name'],
  components: {
    Header,
    Navigation,
    Footer,
  },
  data() {
    return {
    }
  }
});
