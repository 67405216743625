
import { defineComponent, inject, ref, reactive, watch, computed, onMounted } from 'vue'
import { Emitter } from 'mitt'
import store from '@/store'

import { DateTime } from 'luxon'
import { DatePicker } from 'v-calendar'

import { useI18n } from 'vue-i18n'
import Multiselect from '@vueform/multiselect'

export default defineComponent({
  name: 'CalendarDisplay',
  components: {
    Multiselect,
    DatePicker
  },
  props: ['bien', 'hidePeopleSelector'],
  setup(props) {
    const $emitter = inject('$emitter') as Emitter
    const $ContentManagerUrl = inject('$ContentManagerUrl')
    const { t } = useI18n({ useScope: 'global' })
    const price = ref(0)
    
    const updatePrice = (value: number) => {
      price.value = value
    }
    const priceClass = computed(() => {
      if(price.value == -1) {
        return 'error'
      } else if (price.value == 0) {
        return 'neutral'
      } else {
        return 'success'
      }
    })

    // For V-Calendar
    const untouched = ref(true)
    const loadingQuote = ref(false)
    const modelConfig = reactive({
      timeAdjust: '12:00:00',
    })
    const month = ref({
      start: DateTime.now(),
      end: DateTime.now()
    })
    const attrs = ref([
      {
        key: 'booked',
        highlight: {
          //color: 'red',
          //fillMode: 'light',
          contentClass: 'vc-booked-date'
        },
        bar: 'red',
        dates: [] as any[],
        popover: { label: t('home.availability.popovers.0'), hideIndicator: true },
        order:2
      },
      {
        key: 'canLeave',
        highlight: {
          //color: 'orange',
          //fillMode: 'light',
          contentClass: 'vc-okay-date'
        },
        bar: 'green',
        dates: [] as any[],
        popover: { label: t('home.availability.popovers.1'), hideIndicator: true },
        order:1
      },
      {
        key: 'open',
        highlight: {
          //color: 'orange',
          //fillMode: 'light',
          contentClass: 'vc-open-date'
        },
        bar: 'green',
        dates: [] as any[],
        popover: { label: t('home.availability.popovers.2'), hideIndicator: true },
        order:1
      }
    ])
    const previousPage = ref({month: 0, year: 0})
    const commonCalendarDate = computed({
      get: () => {
        return store.state.commonCalendarDate
      },
      set: (value: any) => {
        store.commit('changeCommonCalendarDate', value)
      }
    })
    const commonNumberPeople = computed({
      get: () => {
        return store.state.commonNumberPeople
      },
      set: (value: any) => {
        store.commit('changeCommonNumberPeople', value)
      }
    })
    
    const datepicker = ref(null as any)
    

    $emitter.on('movePreviousMonth',  async(date) => {
      await datepicker.value.move({month:date.month+1, year: date.year}, {force:true}) //+1 car les mois commencent à 1

    })
    $emitter.on('moveNextMonth',  async(date) => {
      await datepicker.value.move({month:date.month+1, year: date.year}, {force:true}) //+1 car les mois commencent à 1
    })


    const lodgifyCheckMonthAvailability = async() => {
      const response = await fetch($ContentManagerUrl+"/lodgify-calls/month-availability?"+ new URLSearchParams({
        start: month.value.start.toISODate(),
        end: month.value.end.toISODate(),
        propertyId: props.bien.lodgify_propertyId,
      }), {
        method: "GET",
      })

      if(response.ok) {
        const data = await response.json()

        attrs.value[0].dates = [] // This is the 'busy' array
        attrs.value[1].dates = [] // This is the 'morning free' array
        attrs.value[2].dates = [] // This is the 'free' array

        let previous: any
        data[0].periods.forEach((element: any) => {
          if(element.available === 0) {
            // Push each period to the "busy" attribute
            attrs.value[0].dates.push( { start: new Date(element.start), end: new Date(element.end) })

            // Push each start date to "bookable"
            if(previous) {
              const a = new Date(new Date(previous.end).getTime() + 60 * 60 * 24 * 1000)
              const b = new Date(element.start)

              //console.log(a + ' / ' +b)

              if(+a !== +b) { // We use "+" to transform a Date to its Number
                attrs.value[1].dates.push( { start: new Date(element.start), end: new Date(element.start) })
              }
            } else {
              attrs.value[1].dates.push( { start: new Date(element.start), end: new Date(element.start) })
            }
            previous = element
          }
          else if(element.available === 1){
            attrs.value[2].dates.push( { start: new Date(element.start), end: new Date(element.end) })
          }
        })

        attrs.value = attrs.value.slice() // This refreshes the calendar view :)

      } else {
        throw Error(response.statusText);
      }
    }

    const updateRange = (page: { month: number; year: number }) => {
      if(page.month == previousPage.value.month && page.year == previousPage.value.year) {
        //console.log("samepage")
      } else {
        if (DateTime.now().set({year: page.year, month:page.month-1}).startOf('month') < DateTime.now()) {
          month.value.start = DateTime.now()
          //console.log('before')
        } else {
          month.value.start = DateTime.now().set({year: page.year, month:page.month-1}).startOf('month')
          //console.log('not before')
        }
        month.value.end = DateTime.now().set({year: page.year, month:page.month+1}).endOf('month')
        lodgifyCheckMonthAvailability()
        previousPage.value = page
      }
    }

    

    const lodgifyGetRangeQuote = async() => {
      loadingQuote.value = true

      const response = await fetch($ContentManagerUrl+"/lodgify-calls/range-quote/"+props.bien.lodgify_propertyId+"?"+ new URLSearchParams({
        arrival: store.state.commonCalendarDate.start.toISOString(),
        departure: store.state.commonCalendarDate.end.toISOString(),
        "roomTypes[0].id": props.bien.lodgify_roomId,
        "roomTypes[0].people": store.getters.commonNumberPeople
      }),{
        method: "GET",
      })

      if(response.ok) {
        const data = await response.json()
        if(data[0]) {
          price.value = data[0].total_including_vat
        } else {
          price.value = -1
        }
      } else {
        throw Error(response.statusText);
      }

      loadingQuote.value = false
    }

    watch(() => store.state.commonNumberPeople, () => {
      lodgifyGetRangeQuote()
      untouched.value = false
    })

    watch(() => store.state.commonCalendarDate, () => {
      lodgifyGetRangeQuote()
      untouched.value = false
    })
    
    

    return {
      price, updatePrice, loadingQuote,
      priceClass,

      //v-calendar
      datepicker, modelConfig, attrs, updateRange, commonCalendarDate, commonNumberPeople
    }
  }
})
