
import { defineComponent } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import LocaleSwitch from '@/components/LocaleSwitch.vue'
import getGlassContainersQuery from '@/graphql/glassContainer/gets.gql'

export default defineComponent({
  name: 'Bien',
  inject: ['EnvironmentVariables'],
  props: ['bien_name'],
  components: {
    LocaleSwitch,
  },
  data() {
    return {
    }
  },
  setup() {
    const { result: containersQR } = useQuery(getGlassContainersQuery)
    console.log( { containersQR } )
    return { containersQR }
  },
  methods: {
    scrollTo(elementId: string) {
      const element: HTMLElement | null = document.getElementById(elementId);
      console.log(element)
      if(element) 
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }
  }
});
