import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_JbWebpage = _resolveComponent("JbWebpage")!

  return (_openBlock(), _createBlock(_component_JbWebpage, {
    "jb-theme": "gss",
    class: "public"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}