
import { defineComponent, inject, ref, computed } from 'vue';
import { Emitter } from 'mitt'

import CalendarDisplay  from '@/components/CalendarDisplay.vue'

import { useQuery, useResult } from '@vue/apollo-composable'
import getBiensQuery from '@/graphql/bien/gets.gql'

import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Biens',
  components: {
    CalendarDisplay
  },
  props: ['bien'],
  setup() {
    const { result } = useQuery(getBiensQuery)
    const $emitter = inject('$emitter') as Emitter
    const $ContentManagerUrl = inject('$ContentManagerUrl')
    const { t } = useI18n({ useScope: 'global' })

    const calendarToggle = ref(false)


    const biensQR = useResult(result, null, data => data.biens)

    const openModal = (name: string) => {
      $emitter.emit("open-modal-"+name)
    }
  
    const price = ref(0)
    
    const updatePrice = (value: number) => {
      price.value = value
    }
    const priceClass = computed(() => {
      if(price.value == -1) {
        return 'error'
      } else if (price.value == 0) {
        return 'neutral'
      } else {
        return 'success'
      }
    })

    const toggleCalendar = () => {
      calendarToggle.value = !calendarToggle.value
    }
    const modalBookClick = (name: string) => {
      calendarToggle.value = true
      $emitter.emit("close-modal-"+name)
    }
    

    // For the slider
    const image = (photo: any) => {
      if(photo.formats && photo.formats.large)
        return $ContentManagerUrl+photo.formats.large.url
      else
        return $ContentManagerUrl+photo.url
    }


    return {
      biensQR,
      openModal,
      price, updatePrice,
      priceClass,

      //slider
      image,

      toggleCalendar, calendarToggle, modalBookClick
    }
  }
});
