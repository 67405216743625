
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Contact',
  data() {
    return {
      locale: '',
      formCallBack : {
        formname: "formCallBack",
        phone: {
          value:'',
          error: false,
          success: false,
        },
        sent: false,
        errors: false
      },
      formMessage: {
        formname: "formMessage",
        name: {
          value:'',
          error: false,
          success: false,
        },
        email: {
          value:'',
          error: false,
          success: false,
        },
        phone: {
          value:'',
          error: false,
          success: false,
        },
        message: {
          value:'',
          error: false,
          success: false,
        },
        sent: false,
        errors: false
      }
    }
    
  },
  methods: {
    async callBack(form: any) {
      if(this.checkForm(form) === true) {
        const response = await fetch(this.$ContentManagerUrl+"/mailing/call-back", {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            phone: this.formCallBack.phone.value
          })
        })

        if(response.ok) {
          this.formCallBack.sent = true
          //const data = await response.json()
          //console.log(data)


        }
      }
    },
    async sendMessage(form: any) {
      if(this.checkForm(form) === true) {
        const response = await fetch(this.$ContentManagerUrl+"/mailing/send-message", {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: this.formMessage.name.value,
            email: this.formMessage.email.value,
            phone: this.formMessage.phone.value,
            message: this.formMessage.message.value
          })
        })

        if(response.ok) {
          this.formMessage.sent = true
          //const data = await response.json()
          //console.log(data)


        }
      }
    },
    checkForm(form: any): boolean {
      let isValid = true

      if(form.formname == "formCallBack") {
        if(form.phone.value !== "") {
          form.phone.success = true
          form.phone.error = false
        } else {
          form.phone.success = false
          form.phone.error = true
          isValid = false
        }
      }

      else if (form.formname == "formMessage") {
        if(form.name.value !== "") {
          form.name.success = true
          form.name.error = false
        } else {
          form.name.success = false
          form.name.error = true
          isValid = false
        }
        if(form.email.value !== "") {
          form.email.success = true
          form.email.error = false
        } else {
          form.email.success = false
          form.email.error = true
          isValid = false
        }
        if(form.phone.value !== "") {
          form.phone.success = true
          form.phone.error = false
        } else {
          form.phone.success = false
          form.phone.error = true
          isValid = false
        }
        if(form.message.value !== "") {
          form.message.success = true
          form.message.error = false
        } else {
          form.message.success = false
          form.message.error = true
          isValid = false
        }
      }
      

      return isValid
    },
    inputClass(input: any): string {
      if(input.success === true) {
        return "success"
      } else if(input.error === true) {
        return "error"
      } else {
        return ""
      }
    },
    formSent(form: any) {
      if(form.sent === true) {
        return true
      } else if(form.errors === true)
        return false
      return false 
    }
  }
})
