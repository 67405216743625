
import { defineComponent, inject, ref, reactive } from 'vue'
import { Emitter } from 'mitt'

export default defineComponent({
  name: 'Admin Home',
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter
    const $ContentManagerUrl = inject('$ContentManagerUrl')

    // get Reservations from Lodgify (or Database if already fetched)
    const reservations = reactive({lodgify_response:{}})
    const totalReservations = ref(0)
    const lodgifyGetTotalReservations = async() => {
      const response = await fetch($ContentManagerUrl+"/lodgify-calls/reservations?"+ new URLSearchParams({
        includeCount: "true",
        stayFilter: '1',
        includeExternal: "true",
        page: '1',
        size: '100'
      }), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('jwt')}`
        },
      })

      if(response.ok) {
        const data = await response.json()

        totalReservations.value = data.count
      } else {
        throw Error(response.statusText);
      }
    }

    // get Availability periods from Lodgify (or Database if already fetched)
    /*const availabilities = reactive({lodgify_response:{}})
    const lodgifyGetAvailability = async() => {
      const response = await fetch($ContentManagerUrl+"/lodgify-calls/month-availability?"+ new URLSearchParams({
        start: '2021-06-01',
        end: '2021-06-30',
        propertyId: '329673',
      }), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('jwt')}`
        },
      })

      if(response.ok) {
        console.log(response)
        availabilities.lodgify_response = await response.json()
      } else {
        throw Error(response.statusText);
      }
    }*/

    lodgifyGetTotalReservations()

    return {
      reservations,
      totalReservations,
      //lodgifyGetReservations,

      //availabilities,
      //lodgifyGetAvailability,
    }
  }
})
