import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Video = _resolveComponent("Video")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Biens = _resolveComponent("Biens")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_JbMainContainer = _resolveComponent("JbMainContainer")!

  return (_openBlock(), _createBlock(_component_JbMainContainer, {
    padding: "0",
    onScrollPassive: _ctx.handleScroll,
    class: _normalizeClass([_ctx.scrollClass, "home"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Navigation),
      _createVNode(_component_Video),
      _createVNode(_component_Header),
      _createVNode(_component_router_view),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Biens),
        _createVNode(_component_Contact)
      ]),
      _createVNode(_component_Footer)
    ]),
    _: 1
  }, 8, ["onScrollPassive", "class"]))
}