import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createVNode(_component_router_link, {
      class: "icon",
      to: '/'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "home" })
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: "menu-btn",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$store.commit('toggleMenu')), ["stop"]))
    }, [
      _createVNode(_component_font_awesome_icon, { icon: "bars" })
    ])
  ]))
}