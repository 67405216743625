import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Public from './views/Public.vue'
import Home from './views/public/Home.vue'
import Availability from './views/public/Availability.vue'
import Presentation_Selection from './views/public/presentation/LodgingSelection.vue'
import Presentation_Bien from './views/public/presentation/Presentation.vue'
import Livret_Selection from './views/public/booklet/LodgingSelection.vue'
import Livret_Bien from './views/public/booklet/Booklet.vue'
import Rulebook from './views/public/Rulebook.vue'
import Mentions from './views/public/Mentions.vue'
import Partners from './views/public/Partners.vue'
import Admin from './views/Admin.vue'
import ALogin from './views/admin/Login.vue'
  import AHome from './views/admin/Home.vue'
  import AStatistiques from './views/admin/Statistiques.vue'
  import AFactures from './views/admin/Invoices.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Root',
    component: Public,
    children: [
      {
        path: '',
        component: Home
      },
      {
        path: 'reserver',
        component: Availability
      },
      {
        path: 'mentions-legales',
        component: Mentions
      },
      {
        path: 'partenaires',
        component: Partners
      },
      {
        path: 'visiter',
        component: Presentation_Selection
      },
      {
        path: 'visiter/:bien_name',
        props: true,
        component: Presentation_Bien
      },
      {
        path: 'livret-accueil',
        component: Livret_Selection
      },
      {
        path: 'livret-accueil/:bien_name',
        props: true,
        component: Livret_Bien
      },
      {
        path: 'reglement',
        props: true,
        component: Rulebook
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: ALogin,
  },
  {
    path: '/admin',
    component: Admin,
    meta: { authorize: ["Authenticated"] },
    children: [
      {
        path: 'home',
        name: 'AdminHome',
        component: AHome,
      },
      {
        path: 'logout',
        name: 'Logout',
        redirect: 'login'
      },
      {
        path: 'statistiques',
        component: AStatistiques
      },
      {
        path: 'factures',
        component: AFactures
      },
    ]
  },
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Is the route protected
  if (to.meta.authorize) {
    // So is the user connected ?
    if (sessionStorage.getItem("role")) {
      // And is the user authorized to be there ?
      if ((to.meta.authorize as any[]).includes(sessionStorage.getItem("role"))) {
        // Alright you may enter
        next();
      } else {
        // Redirect this impostor !
        next('/login');
      }
    } else {
      // Redirect this impostor !
      next('/login');
    }

    //if(sessionStorage.getItem("role"))
    //console.log("I am a "+sessionStorage.getItem("role"))

  } else {
    // If user wants to log out
    if (to.redirectedFrom) {
      if (to.redirectedFrom.name == "Logout")
        sessionStorage.clear()
    }
    next();
  }

})

export default router
