import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "testemonies" }
const _hoisted_2 = { class: "vignette" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = { class: "photo" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JbSlider = _resolveComponent("JbSlider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('home.testemonies.title')), 1),
    _createVNode(_component_JbSlider, { id: "avis" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.biens, (bien) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: bien }, [
            (bien.reviews.length > 0)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(bien.reviews, (review, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: review,
                    class: "slide",
                    style: _normalizeStyle(index == 0 ? 'margin-left: -'+_ctx.$store.getters.currentSelectedTestemony+'00%;' : '')
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("img", {
                            src: _ctx.$ContentManagerUrl+review.photo.url
                          }, null, 8, _hoisted_5)
                        ]),
                        _createElementVNode("p", null, _toDisplayString(review.name), 1)
                      ]),
                      _createElementVNode("blockquote", _hoisted_6, _toDisplayString(review.text), 1)
                    ])
                  ], 4))
                }), 128))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _: 1
    })
  ]))
}