
import { defineComponent } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import CaseBien from '@/components/Case_bien.vue'
import LocaleSwitch from '@/components/LocaleSwitch.vue'
import getBiensQuery from '@/graphql/bien/gets.gql'

export default defineComponent({
  name: 'Livret_selection',
  components: {
    CaseBien,
    LocaleSwitch,
  },
  data() {
    return {
      
    }
  },
  setup() {
    const { result } = useQuery(getBiensQuery)
    return { result }
  },
});
