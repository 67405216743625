import { ref, reactive } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'

export function searchHelper(elementName: string, query: any): any {
  const searchListPaging = reactive({
    max: 0,
    searchTerm: ref('')
  })

  const searchOptions = reactive({ enabled: false })
  const { result } = useQuery(query, searchListPaging, searchOptions)
  const ElementsList = useResult(result, null, data => data[elementName])

  const searchFunction = (searchTerm: string) => {
    // When this value changes, it triggers a new query
    searchListPaging.searchTerm = searchTerm
    searchOptions.enabled = true
  }

  return { searchFunction, ElementsList/*, ElementsDisplayList*/ }
}

// Here we declare the format of all object types when they appear in a search result box
export function toSearchListElement(element: { [key: string]: any }, elementType: string): any {
  const rData: { id: number; name: string } = { id: 0, name: '' }
  switch (elementType) {
    case 'reservation': {
      let currency = "?$?"
      if (element.currency) {
        currency = element.currency.symbol
      }

      let bien_name = "Maison inconnue"
      if (element.bien) {
        bien_name = element.bien.name
      }
      rData.id = element.id
      rData.name = 'N°'+element.lodgify_id+' dans "'+bien_name+'" pour '+element.guest.name
      break;
    }
    default: {
      rData.id = element.id
      rData.name = element.id
    }
  }
  return rData
}

export function toSearchList(rawList: any, type: string): any {
  if (rawList.value) {
    return rawList.value.map((element: any) => {
      return toSearchListElement(element, type)
    })
  } else return []
}

export function searchValueNulllIfZero(searchFieldValue: string): string | null {
  let response: string | null = null
  if (searchFieldValue != "0") {
    response = searchFieldValue
  }
  return response
}