
import { defineComponent, inject, computed, reactive, ref } from 'vue'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { Emitter } from 'mitt'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { DatePicker } from 'v-calendar'
import getInvoicesQuery from '@/graphql/invoice/gets_bySci.gql'
import getInvoiceBatchQuery from '@/graphql/invoice/gets_batch.gql'
import getScisQuery from '@/graphql/sci/gets.gql'
import generateInvoiceMutation from '@/graphql/invoice/generate.gql'
import sendByEmailInvoiceMutation from '@/graphql/invoice/sendByEmail.gql'

import InvoiceUpdateModal from '@/components/modals/InvoiceUpdate.vue'

export default defineComponent({
  name: 'Stratistiques',
  components: {
    InvoiceUpdateModal,
    DatePicker
  },
  setup() {
    // get Global properties
    const contentManagerUrl = inject('$ContentManagerUrl')
    const emitter = inject('$emitter') as Emitter

    // generate Invoice
    const { mutate: _generateInvoice } = useMutation(generateInvoiceMutation)

    const generateInvoice = (id: number) => {
      const queryInput = {
        id: id
      }
      _generateInvoice(queryInput)
    }

    // sendByEmail Invoice
    const { mutate: _sendByEmailInvoice } = useMutation(sendByEmailInvoiceMutation)

    const sendByEmailInvoice = (id: number) => {
      const queryInput = {
        id: id
      }
      _sendByEmailInvoice(queryInput)
    }

    // get Invoices list
    const invoiceListPaging = reactive({
      arisma: {
        start: 0,
        limit: 100,
        max: 0
      },
      maisar: {
        start: 0,
        limit: 100,
        max: 0
      },
      arnaud: {
        start: 0,
        limit: 100,
        max: 0
      },
      matthieu: {
        start: 0,
        limit: 100,
        max: 0
      }
    })
    const { result: getInvoicesResult, loading: getInvoicesLoading, error: getInvoicesError, refetch: refetchInvoices } = useQuery(getInvoicesQuery, () => ({ a_start: invoiceListPaging.arisma.start, a_limit: invoiceListPaging.arisma.limit, m_start: invoiceListPaging.maisar.start, m_limit: invoiceListPaging.maisar.limit}))
    const invoices_arisma = useResult(getInvoicesResult, null, data => data.invoices_arisma)
    const invoices_maisar = useResult(getInvoicesResult, null, data => data.invoices_maisar)
    const invoices_arnaud = useResult(getInvoicesResult, null, data => data.invoices_arnaud)
    const invoices_matthieu = useResult(getInvoicesResult, null, data => data.invoices_matthieu)
    const invoicesCountArisma = useResult(getInvoicesResult, null, data => data.count_invoices_arisma.aggregate.count)
    const invoicesCountMaisar = useResult(getInvoicesResult, null, data => data.count_invoices_maisar.aggregate.count)
    const invoicesCountArnaud = useResult(getInvoicesResult, null, data => data.count_invoices_arnaud.aggregate.count)
    const invoicesCountMatthieu = useResult(getInvoicesResult, null, data => data.count_invoices_matthieu.aggregate.count)
    invoiceListPaging.arisma.max = invoicesCountArisma as any
    invoiceListPaging.maisar.max = invoicesCountMaisar as any
    invoiceListPaging.arnaud.max = invoicesCountArnaud as any
    invoiceListPaging.matthieu.max = invoicesCountMatthieu as any

    emitter.on('invoices-refresh', () => {
      refetchInvoices()
    })

    // Get scis
    const { result: getScisResult } = useQuery(getScisQuery)
    const scis = useResult(getScisResult, null, data => data.scis)

    // get Invoices list
    const invoiceBatchListPaging = reactive({
      start: new Date(),
      limit: new Date(),
      sci: undefined,
      count: 0
    })

    const { result: getInvoiceBatchResult, loading: getInvoiceBatchLoading, onResult: getInvoiceBatchOnResult } = useQuery(getInvoiceBatchQuery, () => ( {start: helpers.dateobjToISO(invoiceBatchListPaging.start), limit: helpers.dateobjToISO(invoiceBatchListPaging.limit), sci:invoiceBatchListPaging.sci} ))
    const invoiceBatch = useResult(getInvoiceBatchResult, null, data => data.invoiceBatch)
    const invoiceBatchCount = useResult(getInvoiceBatchResult, null, data => data.invoiceBatchCount.aggregate.count)
    invoiceBatchListPaging.count = invoiceBatchCount as any

    
    const invoiceBatchWithFileCount = ref(0)
    getInvoiceBatchOnResult (QR => {
      invoiceBatchWithFileCount.value = 0
      for(const invoice in invoiceBatch.value) {
        if(invoiceBatch.value[invoice].file && invoiceBatch.value[invoice].file.length > 0) {
          invoiceBatchWithFileCount.value++
        }
      }
    })

    // Invoice ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id 
      emitter.emit("open-modal-invoice-update")
    }

    const buttonSentence = computed(() => {
      if(getInvoiceBatchLoading.value) {
        return 'Chargement...'
      } else {
        if(invoiceBatchListPaging.count === 0) {
          return 'Aucune facture trouvée'
        } else {
          return invoiceBatchListPaging.count+' facture(s), dont '+invoiceBatchWithFileCount.value+' avec PDF.'
        }
      }
    })

    return {
      generateInvoice,
      sendByEmailInvoice,

      invoices_arisma,
      invoices_maisar,
      invoices_arnaud,
      invoices_matthieu,
      invoiceListPaging,
      getInvoicesLoading,
      getInvoicesError,

      scis,
      invoiceBatchListPaging,
      invoiceBatch,
      invoiceBatchCount,
      invoiceBatchWithFileCount,

      setupEditModal,
      updateId,
      emitter,

      buttonSentence,

      invoiceHelpers
    }
    
  }
})
