// General notes
// Switching from dev to prod requires a new build of strapi via "NODE_ENV=production yarn build" then "NODE_ENV=production yarn start"

import { createApp, h, provide } from 'vue'
import App from './App.vue' // Program entry
import './registerServiceWorker'

// Hand crafted
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { setContext } from '@apollo/client/link/context'
import { createI18n } from 'vue-i18n'
import store from './store'
import router from './router'
import { EnvironmentVariablesInterface } from './modules/interfaces'
import VueGtag from "vue-gtag"

// Hey hey hey it's me !
import JbLib from 'jb-lib'
import 'jb-lib/dist/jb-lib.esm.css'

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUsers, faCalendarAlt, faCamera, faSpinner, faSearch, faSignOutAlt, faDownload, faEnvelope, faVolumeUp, faVolumeMute, faArrowLeft, faArrowRight, faBars, faPhone, faFont, faPeopleArrows, faKey, faUser, faPen, faFileInvoiceDollar, faEye, faTimes, faTimesCircle, faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faPlus, faMinus, faCheckCircle, faExclamationCircle, faCog, faCogs, faBed, faHome, faDoorOpen, faHandshake } from '@fortawesome/free-solid-svg-icons'
library.add(faUsers, faCalendarAlt, faCamera, faSpinner, faSearch, faSignOutAlt, faDownload, faEnvelope, faVolumeUp, faVolumeMute, faArrowLeft, faArrowRight, faBars, faPhone, faFont, faPeopleArrows, faKey, faUser, faPen, faFileInvoiceDollar, faEye, faTimes, faTimesCircle, faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faPlus, faMinus, faCheckCircle, faExclamationCircle, faCog, faCogs, faBed, faHome, faDoorOpen, faHandshake)

// Set environment dependent variables
const EnvironmentVariables = {
    ContentManagerUrl: 'http://192.168.1.11:5081', // local dev
    defaultLocale: 'fr',
} as EnvironmentVariablesInterface

if (process.env.VUE_APP_MODE == "live")
  EnvironmentVariables['ContentManagerUrl'] = "https://gites-spa-sainte-maxime.fr/api"
else if(process.env.VUE_APP_MODE == "test")
  EnvironmentVariables['ContentManagerUrl'] = "https://test.gites-spa-sainte-maxime.fr/api"


// Activate global properties
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $ContentManagerUrl: string;
    $AppTesting: boolean;
    $DefaultLocale: string;
  }
}

// HTTP connection to the API
const httpLink = createHttpLink({
uri: EnvironmentVariables['ContentManagerUrl']+'/graphql',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem('jwt');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const defaultClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

// I18n messages
import { fr } from './modules/translations.fr'
import { en } from './modules/translations.en'
import { de } from './modules/translations.de'

const messages = {fr, en, de}
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'fr',
  fallbackLocale: ['fr'],
  messages
})

// Event emitter
import mitt from 'mitt';
const emitter = mitt();

const vueApp = createApp({
    data() {
      return {
        sharedState: store.state
      }
    },
    setup () {
        provide(DefaultApolloClient, defaultClient)
    },
    render() {
        return h(App)
    }
})

vueApp.config.globalProperties.$ContentManagerUrl = EnvironmentVariables['ContentManagerUrl']
vueApp.config.globalProperties.$DefaultLocale = 'fr' // Not used but it's here

if (process.env.NODE_ENV == "development" || process.env.VUE_APP_MODE == "test")
  vueApp.config.globalProperties.$AppTesting = true
else
  vueApp.config.globalProperties.$AppTesting = false

vueApp.use(router).use(store).use(i18n).use(JbLib).use(VueGtag, {
  config: { id: "G-5JMJHKLY80" }
}, router)
vueApp.provide('$ContentManagerUrl', EnvironmentVariables['ContentManagerUrl'])
vueApp.provide('$emitter', emitter)
vueApp.component("font-awesome-icon", FontAwesomeIcon)
vueApp.mount('#app')
