
import { defineComponent } from 'vue'
import { JbSlider } from 'jb-lib'

export default defineComponent({
  name: 'Testemonies',
  components: {
    JbSlider
  },
  props: ['biens'],
  data() {
    return {
      
    }
  },
});
