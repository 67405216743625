import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "texts" }
const _hoisted_3 = { class: "address" }
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "icons" }
const _hoisted_6 = { class: "btns-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JbSlider = _resolveComponent("JbSlider")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_JbButton = _resolveComponent("JbButton")!
  const _component_CalendarDisplay = _resolveComponent("CalendarDisplay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_JbSlider, {
      id: _ctx.bien.link,
      fullscreenable: true
    }, {
      default: _withCtx(() => [
        (_ctx.bien.gallery.length > 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.bien.gallery, (photo, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: photo,
                class: "slide",
                style: _normalizeStyle(index == 0 ? 'margin-left: -'+_ctx.$store.getters.currentSelectedTestemony+'00%;' : '')
              }, [
                _createElementVNode("img", {
                  src: _ctx.image(photo)
                }, null, 8, _hoisted_1)
              ], 4))
            }), 128))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["id"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, [
        _createVNode(_component_router_link, {
          to: '/visiter/'+_ctx.bien.link
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.bien.name), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.bien.address.city) + ", Côte-d'Azur", 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('biens.'+_ctx.bien.link+'.short')), 1),
      _createElementVNode("p", _hoisted_5, [
        _createElementVNode("span", null, [
          _createVNode(_component_font_awesome_icon, { icon: "bed" }),
          _createTextVNode(_toDisplayString(_ctx.bien.nbRooms) + " " + _toDisplayString(_ctx.$t('words.bedroom', 2)), 1)
        ]),
        _createElementVNode("span", null, [
          _createVNode(_component_font_awesome_icon, { icon: "users" }),
          _createTextVNode(_toDisplayString(_ctx.bien.maxUsers) + " " + _toDisplayString(_ctx.$t('words.person', 2)), 1)
        ])
      ]),
      _createElementVNode("p", _hoisted_6, [
        _createVNode(_component_router_link, {
          to: '/visiter/'+_ctx.bien.link
        }, {
          default: _withCtx(() => [
            _createVNode(_component_JbButton, { size: "l" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('home.availability.button.details')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_JbButton, {
          size: "l",
          color: "error",
          onClick: _ctx.toggleCalendar
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('home.availability.button.book')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _withDirectives(_createVNode(_component_CalendarDisplay, { bien: _ctx.bien }, null, 8, ["bien"]), [
      [_vShow, _ctx.calendarToggle]
    ])
  ], 64))
}