import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_JbCard = _resolveComponent("JbCard")!
  const _component_JbPanel = _resolveComponent("JbPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_JbPanel, {
      title: "Réservations",
      icon: "user",
      id: "users-list"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.lodgifyGetReservations && _ctx.lodgifyGetReservations(...args)))
        }, "Get all reservations"),
        _createElementVNode("div", {
          class: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.lodgifyGetAvailability && _ctx.lodgifyGetAvailability(...args)))
        }, "Get availability"),
        _createElementVNode("div", _hoisted_1, [
          (Object.keys(_ctx.reservations.lodgify_response).length !== 0)
            ? (_openBlock(), _createBlock(_component_JbCard, {
                key: 0,
                icon: "user",
                title: "Nombre de réservations",
                number: _ctx.reservations.lodgify_response.count+''
              }, null, 8, ["number"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ], 64))
}