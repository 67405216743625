
import { defineComponent } from 'vue';
//import CookiesNotice from '@/components/CookiesNotice.vue';

export default defineComponent({
  name: 'App',
  components: {
    //CookiesNotice
  }
});
