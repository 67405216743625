
import { defineComponent } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import getBiensQuery from '@/graphql/bien/gets.gql'
import { helpers } from '@/helpers'

// My components
import Navigation from '@/components/Navigation.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'Livret_selection',
  components: {
    Navigation,
    Header,
    Footer
  },
  data() {
    return { }
  },
  setup() {
    const { result } = useQuery(getBiensQuery)
    return { 
      result,
      helpers
   }
  }
});
