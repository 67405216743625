
import { defineComponent, inject, ref, reactive, watch, computed } from 'vue';
import { Emitter } from 'mitt'
import { helpers } from '@/helpers'
import store from '@/store'

import CalendarDisplay from '@/components/CalendarDisplay.vue'
import { DatePicker } from 'v-calendar'

import { useQuery, useResult } from '@vue/apollo-composable'
import getBiensQuery from '@/graphql/bien/gets.gql'

import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AvailabilityFull',
  components: {
    CalendarDisplay,
    DatePicker
  },
  setup() {
    const { result } = useQuery(getBiensQuery)
    const $emitter = inject('$emitter') as Emitter
    const { t } = useI18n({ useScope: 'global' })

    const globalSelectorDate = ref(new Date())

    const biensQR = useResult(result, null, data => data.biens)

    const openModal = (name: string) => {
      $emitter.emit("open-modal-"+name)
    }
    const closeModal = (name: string) => {
      $emitter.emit("close-modal-"+name)
    }

    const movePreviousMonth = () => {
      globalSelectorDate.value = new Date(globalSelectorDate.value.setMonth( globalSelectorDate.value.getMonth() - 1 ))
      $emitter.emit("movePreviousMonth", {month: globalSelectorDate.value.getMonth(), year:globalSelectorDate.value.getFullYear()})
    }
    const moveNextMonth = () => {
      globalSelectorDate.value = new Date(globalSelectorDate.value.setMonth( globalSelectorDate.value.getMonth() + 1 ))
      $emitter.emit("moveNextMonth", {month: globalSelectorDate.value.getMonth(), year:globalSelectorDate.value.getFullYear()})
    }
  
    const price = ref(0)
    
    const updatePrice = (value: number) => {
      price.value = value
    }
    const priceClass = computed(() => {
      if(price.value == -1) {
        return 'error'
      } else if (price.value == 0) {
        return 'neutral'
      } else {
        return 'success'
      }
    })

    const commonCalendarDate = computed({
      get: () => {
        return store.state.commonCalendarDate
      },
      set: (value: any) => {
        store.commit('changeCommonCalendarDate', value)
      }
    })
    
    const commonNumberPeople = computed({
      get: () => {
        return store.state.commonNumberPeople
      },
      set: (value: any) => {
        store.commit('changeCommonNumberPeople', value)
      }
    })

    const formatedGlobalDate = computed(() => {
      return new Intl.DateTimeFormat('fr', {month: "long"}).format(globalSelectorDate.value)
    })

    return {
      biensQR,
      openModal, closeModal, movePreviousMonth, moveNextMonth,
      price, updatePrice,
      priceClass,
      commonNumberPeople, globalSelectorDate, formatedGlobalDate, commonCalendarDate,

      //slider
      helpers, store
    }
  }
});
