// disable linter camelCase BS
/*eslint-disable */
export const de = {
  home: {
    menu: {
      video: "Startseite",
      availability: "Verfügbarkeit",
      visit: "Besucher",
      testemonies: "Zeugnisse",
      partners: "Partner",
      contact: "Kontakt"
    },
    video: {
      descriptions: {
        1: {
          highlight1: "Fantastische Villa",
          normal1: "für bis zu",
          highlight2: "15 Personen"
        },
        2: {
          normal1: "Ein ganz privater",
          highlight1: "SPA-Bereich",
          normal2: "der",
          highlight2: "24 Stunden am Tag zugänglich ist",
        },
        3: {
          normal1: "Nur",
          highlight1: "5 Minuten von Straßburg entfernt",
        },
        4: {
          normal1: "Viel Spaß mit",
          highlight1: "Familie",
          normal2: "oder",
          highlight2: "Freunden",
        }
      },
      title: "Fünf hochwertige Airbnb-Unterkünfte für Ihren Urlaub, nur fünf Minuten von Straßburg entfernt!",
    },
    availability: {
      title: "Verfügbarkeit",
      h2: "Sehen Sie auf einen Blick unsere verfügbaren Slots",
      popovers: {
        0: "Tag gebucht",
        1: "Kann das Enddatum Ihrer Reise sein",
        2: "Datum offen"
      },
      tooManyUsers: "Diese Unterkunft hat eine maximale Kapazität von {0} Personen",
      button: {
        book: "Online buchen",
        details: "Weitere Details",
        loading: "Suche...",
        select: "Wählen Sie einen Datumsbereich...",
        error: "Unterkunft nicht verfügbar, bitte versuchen Sie es mit einem anderen Zeitraum"
      }
    },
    presentation: {
      title: "Entdecke unsere Airbnb's",
      minStay: "Der Mindestaufenthalt beträgt zwei Tage außer am Sonntagabend.",
      nominStay: "Für diese Unterkunft gibt es keinen Mindestaufenthalt."
    },
    testemonies: {
      title: "Kundenrezensionen"
    },
    contact: {
      title: "Brauchen Sie weitere Informationen?",
      name: "Ihr vollständiger Name",
      email: "Ihre E-Mail-Adresse",
      by_mail: {
        subTitle: "Per E-Mail",
        fill_form: "Bitte füllen Sie dieses Formular aus und wir werden uns so schnell wie möglich bei Ihnen melden."
      },
      by_phone: {
        subTitle: "Rufen Sie uns an unter",
        call: "Telefonisch",
        your_number: "Ihre Telefonnummer",
        called_back: "Oder, wenn Sie zurückgerufen werden möchten, hinterlassen Sie uns Ihre Telefonnummer und wir werden uns im Laufe des Tages mit Ihnen in Verbindung setzen.",
      },
      message: "Geben Sie hier Ihre Nachricht ein",
      send: "Senden",
      sent: "Nachricht gesendet!"
    },
    footer: {
      legal_notice: {
        name: "Impressum"
      },
      welcome_booklet: {
        name: "Willkommensheft"
      },
      rules_booklet: {
        name: "Interner Regelsatz"
      }
    },
    selectDate: "Daten",
    selectNumber: "Anzahl der Reisenden",
    search_btn: "Suche"
  },
  visit: {
    title: "Besuchen Sie unsere Immobilien"
  },
  cookies: {
    sentence: "Wir verwenden Cookies, um die auf unserer Website angebotenen Dienste und Funktionen bereitzustellen und die Erfahrung unserer Benutzer zu verbessern. Cookies werden auf Ihrem Computer oder einem anderen Gerät gespeichert und niemals für andere Zwecke verwendet.",
    button: "Ich verstehe und akzeptiere."
  },
  words: {
    and: "und",
    for: "für",
    bedroom: "Zimmer | Zimmer",
    beds: {
      simple: "Einzelbett | Einzelbetten",
      double: "Doppelbett | Doppelbetten",
      couch: "Schlafsofa | Schlafsofas",
    },
    person: "Person | Menschen",
    call_us: "Rufen Sie uns an",
    villa: "Die villa",
    apartment: "Das Apartment",
    studio: "Das Studio"
  },
  chapters: "Kapitel",
  titles: {
    welcome: {
      h2: "Willkommen!"
    },
    beforeArrival: {
      h2: "Vor Ihrer Ankunft",
      howToFind: {
        h3: "Wie komme ich dorthin?",
        address: "Die Adresse",
        car: "Mit dem Auto",
        train: "Mit dem Zug",
        bus: "Mit dem Bus",
        plane: "Mit dem Flugzeug"
      },
      hours: {
        h3: "Stunden"
      }
    },
    onArrival: {
      h2: "Bei Ihrer Ankunft",
      whereToPark: {
        h3: "Wo parken?"
      },
      goldenRules: {
        h3: "Unsere drei goldenen Regeln"
      },
      products: {
        h3: "Bereitgestellte Produkte / zu planendes Produkt"
      },
      payment: {
        h3: "Mietzahlung (wenn direkt bei uns gebucht)"
      },
      keys: {
        h3: "Schlüsselübergabe"
      }
    },
    manuals: {
      h2: "Gerätehandbücher",
      jacuzzi: {
        h3: "Whirlpool"
      },
      sauna: {
        h3: "Sauna"
      },
      barbecue: {
        h3: "Grillen"
      },
      wifi: {
        h3: "Wifi-Netzwerke"
      },
      dishwasher: {
        h3: "Geschirrspüler"
      },
      toilets: {
        h3: "Sanitäranlagen"
      },
      sorting_trash: {
        h3: "Müll sortieren"
      },
      glass_trash: {
        h3: "Glasabfälle"
      }
    },
    practicalInfo: {
      h2: "Praktische Informationen",
      shops: {
        h3: "Lebensmitteleinkauf und Benzin"
      },
      shops_sunday: {
        h3: "Am Sonntag"
      },
      doctors: {
        h3: "Ärzte, Krankenhäuser, Apotheken"
      },
      other: {
        h3: "Andere"
      }
    },
    goToPlaces: {
      h2: "Gehe zu Orte",
      restaurant_selection: {
        h3: "Unsere Auswahl an Restaurants"
      },
      other_restaurants: {
        h3: "Andere Restaurants"
      },
      gourmet_addresses: {
        h3: "Unsere Adressen für Feinschmecker",
        cheese: {
          h4: "Käse"
        },
        wines: {
          h4: "Wein"
        },
        bakeries: {
          h4: "Bäckereien"
        },
        pastries: {
          h4: "Gebäck"
        },
        caterers: {
          h4: "Caterer"
        },
        vegetables: {
          h4: "Gemüse"
        },
        flowers: {
          h4: "Floristen"
        }
      },
    },
    closeActivities: {
      h2: "Aktivitäten schließen",
      amusement_parcs: {
        h3: "Vergnügungsparks"
      },
      museeums: {
        h3: "Museen / Denkmäler"
      },
      sport_activities: {
        h3: "Outdoor-Aktivitäten"
      },
      other: {
        h3: "Andere"
      }
    },
    uponLeaving: {
      h2: "Beim Verlassen",
      furniture: {
        h3: "Möbel"
      },
      equipment: {
        h3: "Ausstattung"
      },
      windows: {
        h3: "Fenster und Lichter"
      },
      trash: {
        h3: "Mülleimer"
      },
      towels: {
        h3: "Bettwäsche und Handtücher"
      },
      rate_us: {
        h3: "Bewerte uns!"
      }
    },
    other_mentions: {
      h2: "Andere Erwähnungen",
      video_surveillance: {
        h3: "Videoüberwachung"
      },
      fire_safety: {
        h3: "Brandschutzhinweis"
      },
      remark: {
        h3: "Hinweis"
      }
    },
    closing_words: {
      h2: "Schlussworte"
    },
    behavior: {
      name: "Verhalten"
    },
    noise: {
      name: "Lärm"
    },
    tobbaco: {
      name: "Tabak"
    },
    upkeep: {
      name: "Unterhalt"
    },
    trash: {
      name: "Müll"
    },
    rooms: {
      name: "Zimmer"
    },
    water_electricity_heating: {
      name: "Wasser, Strom, Heizung"
    },
    pets: {
      name: "Haustiere"
    },
    toilets: {
      name: "Toiletten"
    },
    bbq: {
      name: "Barbecue"
    },
    insurance: {
      name: "Versicherung"
    },
    fire_safety: {
      name: "Brandschutz"
    },
    leaving: {
      name: "Verlassen"
    },
    jacuzzi: {
      name: "Whirlpool"
    },
    sauna: {
      name: "Sauna"
    },
    caution_table: {
      name: "Vorsichtstabelle"
    },
    sort_guide: {
      name: "Leitfaden zum Sortieren des lokalen Mülls"
    },
    glass_bins: {
      name: "Standorte für Glasbehälter"
    },
    jacuzzi_manual: {
      name: "Jacuzzi-Bedienungsanleitung"
    }
  },
  biens: {
    pageTitle: "Gites & Spa Strasbourg",
    bien_select_text: "Bitte wählen Sie den Ort aus, an dem Sie sich gerade aufhalten:",
    welcome_booklet: {
      pageTitle: "Willkommensbroschüre"
    },
    rules_booklet: {
      pageTitle: "Interner Regelsatz",
      equipment_notice: "Bedienungsanleitung",
      see_on_map: "Auf Google Maps anzeigen"
    },
    h2: {
      overview: "Überblick",
      other: "Zusamenfassend",
      reviews: "Anerkannte Qualität",
      convinced: "Überzeugt ? Jetzt online buchen!"
    },
    'gite-des-freres': {
      short: "Villa 250m² mit privatem Spa, Sauna, Barbecue, Tischfußball, Petanque und vielem mehr",
      long: {
        intro: "",
      },
      presentation: {
        title: "Eine außergewöhnliche Villa",
        h2: {
          activities: "Freizeitaktivitäten für jedes Alter",
          rooms: "Zimmer, die des Hotels würdig sind",
          equipments: "Viele Geräte",
        },
      }
    },
    'la-villa-11': {
      short: "Villa 180m² mit privatem Spa, Sauna, Barbecue, Tischfußball, Petanque und vielem mehr",
      long: {
        intro: "",
      },
      presentation: {
        title: "Eine außergewöhnliche Villa",
        h2: {
          activities: "Freizeitaktivitäten für jedes Alter",
          rooms: "Zimmer, die des Hotels würdig sind",
          equipments: "Viele Geräte",
        },
      }
    },
    'la-villa-15': {
      short: "Villa 200m² mit privatem Spa, Sauna, Barbecue, Tischfußball, Petanque und vielem mehr",
      long: {
        intro: "",
      },
      presentation: {
        title: "Eine außergewöhnliche Villa",
        h2: {
          activities: "Freizeitaktivitäten für jedes Alter",
          rooms: "Zimmer, die des Hotels würdig sind",
          equipments: "Viele Geräte",
        },
      }
    },
    'gite-le-14': {
      short: "Moderne Wohnung mit Jacuzzi, privater Terrasse, Klimaanlage...",
      long: {
        intro: "",
      },
      presentation: {
        title: "Eine schöne und komfortable Wohnung",
        h2: {
          activities: "Freizeitaktivitäten für jedes Alter",
          rooms: "Zimmer für Ihren Komfort",
          equipments: "Viele Geräte",
        },
      }
    },
    'studio-le-14': {
      short: "Modernes Studio mit Jacuzzi, privater Terrasse, Klimaanlage...",
      long: {
        intro: "",
      },
      presentation: {
        title: "Das ideale Studio für Ihren Urlaub",
        h2: {
          activities: "Freizeitaktivitäten für jedes Alter",
          rooms: "Zimmer für Ihren Komfort",
          equipments: "Qualitätsmöbel",
        },
      }
    },
    'villa-le-14': {
      short: "Villa 170m² mit privatem Spa, Sauna, Barbecue, Tischfußball, Petanque und vielem mehr",
      long: {
        intro: "",
      },
      presentation: {
        title: "Eine außergewöhnliche Villa",
        h2: {
          activities: "Freizeitaktivitäten für jedes Alter",
          rooms: "Zimmer, die des Hotels würdig sind",
          equipments: "Viele Geräte",
        },
      }
    },
    
  },
  partners: {
    name: "Unsere Partner",
    discover: "Entdecken",
    sainte_maxime: "Sehr schöne Wohnung in Sainte-Maxime an der Azurküste. Sie ist mit Terrasse, Pool, Spa, Fitnessraum, Balneo und Sauna für einen garantierten Entspannungsaufenthalt ausgestattet."
  }
}