
import { defineComponent, inject, reactive } from 'vue'
import { Emitter } from 'mitt'

export default defineComponent({
  name: 'Stratistiques',
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter
    const $ContentManagerUrl = inject('$ContentManagerUrl')

    // get Reservations from Lodgify (or Database if already fetched)
    const reservations = reactive({lodgify_response:{}})
    const lodgifyGetReservations = async() => {
      const response = await fetch($ContentManagerUrl+"/lodgify-calls/reservations?"+ new URLSearchParams({
        includeCount: "true",
        stayFilter: '1',
        size: '100',
        page: '1'
      }), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('jwt')}`
        },
      })

      if(response.ok) {
        console.log(response)
        reservations.lodgify_response = await response.json()
      } else {
        throw Error(response.statusText);
      }
    }

    // get Availability periods from Lodgify (or Database if already fetched)
    const availabilities = reactive({lodgify_response:{}})
    const lodgifyGetAvailability = async() => {
      const response = await fetch($ContentManagerUrl+"/lodgify-calls/month-availability?"+ new URLSearchParams({
        start: '2021-06-01',
        end: '2021-06-30',
        propertyId: '329673',
      }), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('jwt')}`
        },
      })

      if(response.ok) {
        console.log(response)
        availabilities.lodgify_response = await response.json()
      } else {
        throw Error(response.statusText);
      }
    }

    return {
      reservations,
      lodgifyGetReservations,

      availabilities,
      lodgifyGetAvailability,
    }
  }
})
