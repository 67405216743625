/*export { default as appointmentHelpers } from './helpers/appointmentHelpers'
export { default as invoiceHelpers } from './helpers/invoiceHelpers'
export { default as paymentHelpers } from './helpers/paymentHelpers'
export { default as userHelpers } from './helpers/userHelpers'*/

export const helpers = {
  cm_image(image: { [key: string]: any }, cm_url: string) {
    if (image.formats && image.formats.medium)
      return cm_url + image.formats.medium.url
    else
      return cm_url + image.url
  }
}

