
import { defineComponent, inject, watch, ref, reactive } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { toSearchListElement } from '@/modules/search'

import getInvoiceQuery from '@/graphql/invoice/get.gql'
import updateInvoiceMutation from '@/graphql/invoice/update.gql'
import deleteInvoiceMutation from '@/graphql/invoice/delete.gql'
import getInvoicesQuery from '@/graphql/invoice/gets_bySci.gql'

import InvoiceForm from '@/components/forms/Invoice.form.vue'

export default defineComponent({
  name: 'InvoiceUpdate',
  props: ['elementId', 'listPaging'],
  components: {
    InvoiceForm
  },
  setup(props) {
    // get emitter
    const emitter = inject('$emitter') as Emitter

    const { t } = useI18n({ useScope: 'global' })
    
    const invoiceFormRef = ref<null | any>(null)
  
    ////////// MODAL EDIT ID
    const editingElement = reactive({id: props.elementId})
    const editingElementOptions = reactive({enabled : false})
    const { result: getInvoice, loading: editingElementLoading, onResult: editingElement_onResult } = useQuery(getInvoiceQuery, editingElement, editingElementOptions)
    const updateElementInfos = useResult(getInvoice, null, data => data.invoice)

    editingElement_onResult(queryResult => {
      const form = invoiceFormRef.value.form

      if(queryResult.networkStatus === 7) { // 7 mean OK https://github.com/apollographql/apollo-client/blob/d96f4578f89b933c281bb775a39503f6cdb59ee8/src/core/networkStatus.ts
        //console.log("Invoice Fetch by id ", updateElementInfos.value.id)

        for (const [key] of Object.entries(updateElementInfos.value)) {
          if(key === 'date' || key === 'client_arrival' || key === 'client_departure') {
            form[key] = new Date(updateElementInfos.value[key])
          } else if (key === 'reservation' && updateElementInfos.value[key] !== null) {
            form['reservationId'] = updateElementInfos.value[key].id
            invoiceFormRef.value.preselectedReservation = toSearchListElement(updateElementInfos.value[key], 'reservation')
          } else if (key === 'custom_products') {
            // Dégueulasse sa mère, mais obligé car apollo retourne des objects immuables
            form[key] = helpers.unfreezeProducts(updateElementInfos.value[key])
          } else if (key === 'sci') {
            form['sciId'] = updateElementInfos.value[key].id
          } else { // The rest is automatically set
            form[key] = updateElementInfos.value[key]
          }
        }

      } else {
        console.log("Invoice Fetch by id failed.")
      }
    })

    watch(() => props.elementId, (newValue) => {
      editingElement.id = newValue
      editingElementOptions.enabled = true
    })


    ////////// UPDATE QUERY
    const { mutate: updateInvoice, onDone: updateInvoiceDone, onError: updateInvoiceError } = useMutation(updateInvoiceMutation)
    const updateStatus = reactive({
      success: false,
      error: false
    })
    updateInvoiceDone(() => {
      updateStatus.success = true
      updateStatus.error = false
    })
    updateInvoiceError(() => {
      updateStatus.error = true
      updateStatus.success = false
    })
    
    updateInvoiceDone((QR) => {
      emitter.emit("create-toast", {
        props: {
          title: "Facture modifiée.",
          text: QR.data.updateInvoice.invoice.name,
          icon: "check-circle",
        }
      })
      emitter.emit("close-modal-invoice-update")
    })
    updateInvoiceError(() => {
      console.log('Edit invoice Error')
    })

    ////////// DELETE QUERY
    const { mutate: deleteInvoice, onDone: deleteInvoiceDone, onError: deleteInvoiceError } = useMutation(deleteInvoiceMutation)
    const removeStatus = reactive({
      success: false,
      error: false
    })
    deleteInvoiceDone((QR) => {
      emitter.emit("create-toast", {
        props: {
          title: "Facture supprimée",
          text: QR.data.deleteInvoice.invoice.name,
          icon: "check-circle",
        }
      })
      emitter.emit("close-modal-invoice-update")
      emitter.emit("invoices-refresh")
    })
    deleteInvoiceError(() => {
      removeStatus.error = true
      removeStatus.success = false
    })

    ////////// SUBMIT Modal
    const updateElement = () => {
      const form = invoiceFormRef.value.form
      
      const custom_products: any[] = invoiceHelpers.customProductsToStrapi(form.custom_products)
      
      let reservationId = null
      if(form.reservationId != 0) {
        reservationId = form.reservationId
      }

      const queryInput = { 
        input: { 
          where: {
            id: props.elementId,
          },
          data: {
            name: form.name,
            date: helpers.dateobjToISO(form.date),
            custom_products: JSON.parse(JSON.stringify(custom_products)),
            client_name: form.client_name,
            client_email: form.client_email,
            client_phone: form.client_phone,
            client_arrival: helpers.dateobjToISO(form.client_arrival),
            client_departure: helpers.dateobjToISO(form.client_departure),
            paid: form.paid,
            due: form.to_pay,
            reservation: reservationId,
            sci: form.sciId
          }
        }
      }

      console.log(queryInput)

      updateInvoice(queryInput)
    }

    const deleteElement = () => {
      const queryInput = { 
        input: { 
          where: {
            id: props.elementId,
          }
        }
      }

      deleteInvoice(queryInput)


    }


    // Testing purposes
    const fillForm = () => {
      invoiceFormRef.value.fillForm()
    }


    return {
      invoiceFormRef,
      updateElement,
      deleteElement,
      fillForm,
      updateStatus,
    }
  }
});
