
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Video',
  data() {
    return {
      locale: '',
      selected: '',
      isShown: true,
      videoElement: null as null | HTMLVideoElement,
      textsElement: null as null | NodeList,
      isVideoMuted: true
    }
  },
  methods: {
    pauseVideo() {
      if(this.videoElement !== null) {
        this.videoElement.paused ? this.videoElement.play() : this.videoElement.pause()
      }

      if(this.textsElement !== null) {
        for (const key in this.textsElement) {
          if (Object.prototype.hasOwnProperty.call(this.textsElement, key)) {
            const element = this.textsElement[key] as HTMLElement

            const paused = element.style.animationPlayState === 'paused'
            element.style.animationPlayState = paused ? 'running' : 'paused'
          }
        }
      }
    },
    muteVideo() {
      if(this.videoElement !== null) {
        this.videoElement.muted = !this.videoElement.muted
        this.isVideoMuted = !this.isVideoMuted
      }
    },
    scrollVideo() {
      const header = document.querySelector('.jb-main-container > header') as HTMLElement

      console.dir(header)

      if(header !== null) {
        header.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }
    }
  },
  mounted() {
    this.videoElement = document.getElementById('main-video') as HTMLVideoElement
    this.textsElement = document.querySelectorAll('#texts > p') as NodeList
  },
});
