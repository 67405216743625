
import { defineComponent } from 'vue'
import Footer from '@/components/Footer.vue'

// My components
import Navigation from '@/components/Navigation.vue'
import Header from '@/components/Header.vue'

export default defineComponent({
  name: 'Partenaires',
  components: {
    Navigation,
    Header,
    Footer
  },
  data() {
    return {
    }
  },
  setup() {
    return {}
  }
});
