
import { defineComponent, ref } from 'vue';
import LocaleSwitch from '@/components/LocaleSwitch.vue'

export default defineComponent({
  name: 'Navigation',
  components: {
    LocaleSwitch
  },
  props: ['toggled'],
  setup() {
    const isToggled = ref(false)

    const scrollTo = (div: string) => {
      console.log(div)
      const element: HTMLElement | null = document.getElementById(div)
      console.log(element)
      if(element) {
        element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
      }
    }

    return {
      isToggled,
      scrollTo
    }
  },
  methods: {
    
  }
});
