import { createStore } from 'vuex'

// /!\ Local Storage save everything as string

export default createStore({
  state: {
    locale: '',
    selectedBien: '',
    selectedTestemony: 0,
    menuToggled: false,
    cookiesAccepted: false,
    commonCalendarDate: {
      start: new Date(),
      end: new Date()
    },
    commonNumberPeople: 2
  },
  getters: {
    currentLocale(state) {
      return state.locale
    },
    currentSelectedBien(state) {
      return state.selectedBien
    },
    currentSelectedTestemony(state) {
      return state.selectedTestemony
    },
    menuToggled(state) {
      return state.menuToggled
    },
    cookiesAccepted(state) {
      return state.cookiesAccepted
    },
    commonCalendarDate(state) {
      return state.commonCalendarDate
    },
    commonNumberPeople(state) {
      return state.commonNumberPeople
    }

  },
  mutations: {
    changeLocale (state, newLocale) {
      state.locale = newLocale
      //console.log(state.locale)
    },
    changeSelectedBien(state, newGite) {
      state.selectedBien = newGite
      state.selectedTestemony = 0
      //console.log(state.selectedBien)
    },
    changeSelectedTestemony(state, newTestemony) {
      state.selectedTestemony = newTestemony
      //console.log(state.selectedBien)
    },
    toggleMenu(state) {
      state.menuToggled = !state.menuToggled
    },
    closeMenu(state) {
      state.menuToggled = false
    },
    acceptCookies(state, boolean) {
      state.cookiesAccepted = boolean
      //console.log("Cookies accepted : "+state.cookiesAccepted)
    },
    changeCommonCalendarDate(state, newValue) {
      state.commonCalendarDate = newValue
    },
    changeCommonNumberPeople(state, newValue) {
      state.commonNumberPeople = newValue
    }
  },
  actions: {
  },
  modules: {
  }
})