
import { defineComponent } from 'vue'

// My components
import Navigation from '@/components/Navigation.vue'
import Video from '@/components/Video.vue'
import Header from '@/components/Header.vue'
import Biens from '@/components/Biens.vue'
import Contact from '@/components/Contact.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Navigation,
    Header,
    Video,
    Biens,
    Contact,
    Footer
  },
  data() {
    return {
      selectValue: 2,
      range : {
        start: new Date(),
        end: new Date()
      },
      modelConfig: {
        timeAdjust: '12:00:00',
      },
      scrollClass: "scrolled-top",
      containerElement: 'a',
      navigationToggled: false
    }
  },
  methods: {
    handleScroll() {
      const mainContainerElement: HTMLCollection | null = document.getElementsByClassName("jb-main-container")
      if (mainContainerElement[0].scrollTop > 50 ) {
        this.scrollClass = "scrolling"
      } else {
        this.scrollClass = "scrolled-top"
      }
    }
  },
});
