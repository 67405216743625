
import { defineComponent } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import LocaleSwitch from '@/components/LocaleSwitch.vue'
import getBienQuery from '@/graphql/bien/get.gql'

export default defineComponent({
  name: 'Livret_bien',
  props: ['bien_name'],
  components: {
    LocaleSwitch,
  },
  data() {
    return {
    }
  },
  setup(props) {
    const { result } = useQuery(getBienQuery, {"link": props.bien_name})
    //console.log( { result } )
    return { result }
  },
  methods: {
    scrollTo(elementId: string) {
      const element: HTMLElement | null = document.getElementById(elementId);
      if(element) 
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }
  }
});
