
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
  data() {
    return {
      locale: '',
    }
  },
  /*methods: {
  },
  created() {
  },*/
});
