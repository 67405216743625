
import { defineComponent, inject, ref, reactive, watch, computed } from 'vue';
import { Emitter } from 'mitt'
import store from '@/store'

import Bien from '@/components/Bien.vue'
import Testemonies from '@/components/Testemonies.vue'

import { useQuery, useResult } from '@vue/apollo-composable'
import getBiensQuery from '@/graphql/bien/gets.gql'

import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Biens',
  components: {
    Bien,
    Testemonies
  },
  setup() {
    const { result } = useQuery(getBiensQuery)
    const $emitter = inject('$emitter') as Emitter
    const $ContentManagerUrl = inject('$ContentManagerUrl')
    const { t } = useI18n({ useScope: 'global' })

    const calendarToggle = ref([] as any[])


    const biensQR = useResult(result, null, data => data.biens)

    const openModal = (name: string) => {
      $emitter.emit("open-modal-"+name)
    }
    const closeModal = (name: string) => {
      $emitter.emit("close-modal-"+name)
    }
  
    const price = ref(0)
    
    const updatePrice = (value: number) => {
      price.value = value
    }
    const priceClass = computed(() => {
      if(price.value == -1) {
        return 'error'
      } else if (price.value == 0) {
        return 'neutral'
      } else {
        return 'success'
      }
    })

    return {
      biensQR,
      openModal, closeModal,
      price, updatePrice,
      priceClass,
    }
  }
});
