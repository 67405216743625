
import { defineComponent } from 'vue';
import { EnvironmentVariablesInterface } from '@/modules/interfaces'

export default defineComponent({
  name: 'LocaleSwitch',
  data() {
    return {
      locale: '',
    }
  },
  methods: {
    changeLocale: function (locale: string) {
      localStorage.setItem('locale', locale)
      this.$i18n.locale = locale
      this.$store.commit('changeLocale', locale);
    }
  },
  created() {
    const storedLocale = localStorage.getItem('locale')

    if (storedLocale == null) {
      this.$i18n.locale = 'fr'
      this.$store.commit('changeLocale', 'fr')
    } else {
      this.$i18n.locale = storedLocale
      this.$store.commit('changeLocale', storedLocale)
    }
  },
});
