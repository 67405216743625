import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "page",
  class: "disponibilites"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_AvailabilityFull = _resolveComponent("AvailabilityFull")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_JbMainContainer = _resolveComponent("JbMainContainer")!

  return (_openBlock(), _createBlock(_component_JbMainContainer, {
    padding: "0",
    class: "disponibilites"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Header),
      _createVNode(_component_Navigation),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AvailabilityFull)
      ]),
      _createVNode(_component_Footer)
    ]),
    _: 1
  }))
}