// disable linter camelCase BS
/*eslint-disable */
export const en = {
  home: {
    menu: {
      video: "Home",
      availability: "Availability",
      visit: "Visit",
      testemonies: "Testemonies",
      partners: "Partners",
      contact: "Contact"
    },
    video: {
      descriptions: {
        1: {
          highlight1: "Beautiful villa",
          normal1: "for up to",
          highlight2: "15 people"
        },
        2: {
          normal1: "A totally private",
          highlight1: "SPA area",
          normal2: "accessible",
          highlight2: "24 hours a day",
        },
        3: {
          normal1: "Located just",
          highlight1: "5 minutes from Strasbourg",
        },
        4: {
          normal1: "Have a good time",
          highlight1: "with family",
          normal2: "or",
          highlight2: "friends",
        }
      },
      title: "A beautiful high-standing appartment for your holidays with a swimming pool and gym in Sainte-Maxime!",
    },
    availability: {
      title: "Lodging availability",
      h2: "See at a glance our available slots",
      popovers: {
        0: "Day booked",
        1: "Can be your trip's end date",
        2: "Date open"
      },
      tooManyUsers: "This lodging has a maximum capacity of {0} people",
      button: {
        book: "Book online",
        details: "More details",
        loading: "Searching...",
        select: "Select a date range...",
        error: "Lodging unavailable, please try another date range"
      }
    },
    presentation: {
      title: "Discover our airbnb",
      minStay: "The minimum stay is two days except on sunday evening.",
      nominStay: "There is no minimum stay for this lodging."
    },
    testemonies: {
      title: "Customer reviews"
    },
    contact: {
      title: "Need more information?",
      name: "Your full name",
      email: "Your e-mail address",
      by_mail: {
        subTitle: "By email",
        fill_form: "Please fill this form and we will get back to you as soon as possible."
      },
      by_phone: {
        subTitle: "Call us at",
        call: "By phone",
        your_number: "Your phone number",
        called_back: "Or, if you want to be called back, leave us your phone number and we will contact you withing the day.",
      },
      message: "Type your message here",
      send: "Send",
      sent: "Message sent!"
    },
    footer: {
      legal_notice: {
        name: "Legal notice"
      },
      welcome_booklet: {
        name: "Welcome booklet"
      },
      rules_booklet: {
        name: "Internal ruleset"
      }
    },
    selectDate: "Dates",
    selectNumber: "Number of travelers",
    search_btn: "Search"
  },
  visit: {
    title: "Visit our airbnb"
  },
  cookies: {
    sentence: "We use cookies to provide the services and features offered on our site and to improve the experience of our users. Cookies are stored on your computer or any other device and will never be used for other purposes.",
    button: "I understand and I accept."
  },
  words: {
    and: "and",
    for: "for",
    bedroom: "bedroom | bedrooms",
    beds: {
      simple: "simple bed | simple beds",
      double: "double bed | double beds",
      couch: "couch bed | couch beds",
    },
    person: "person | people",
    call_us: "Call us",
    villa: "The villa",
    apartment: "The apartment",
    studio: "The studio"
  },
  chapters: "Chapters",
  titles: {
    welcome: {
      h2: "Welcome !"
    },
    beforeArrival: {
      h2: "Before your arrival",
      howToFind: {
        h3: "How to get there ?",
        address: "The adress",
        car: "By car",
        train: "By train",
        bus: "By bus",
        plane: "By plane"
      },
      hours: {
        h3: "Hours"
      }
    },
    onArrival: {
      h2: "Upon your arrival",
      whereToPark: {
        h3: "Where to Park ?"
      },
      goldenRules: {
        h3: "Our three golden rules"
      },
      products: {
        h3: "Provided products / product to plan for"
      },
      payment: {
        h3: "Rental payment (if booked directly from us)"
      },
      keys: {
        h3: "Key handover"
      }
    },
    manuals: {
      h2: "Equipment user manuals",
      jacuzzi: {
        h3: "Jacuzzi"
      },
      sauna: {
        h3: "Sauna"
      },
      barbecue: {
        h3: "Barbecue"
      },
      wifi: {
        h3: "Wifi networks"
      },
      dishwasher: {
        h3: "Dishwasher"
      },
      toilets: {
        h3: "Sanitary facilities"
      },
      sorting_trash: {
        h3: "Sorting waste"
      },
      glass_trash: {
        h3: "Glass waste"
      }
    },
    practicalInfo: {
      h2: "Practical informations",
      shops: {
        h3: "Grocery shopping and gas"
      },
      shops_sunday: {
        h3: "On Sunday"
      },
      doctors: {
        h3: "Doctors, hospitals, pharmacies"
      },
      other: {
        h3: "Others"
      }
    },
    goToPlaces: {
      h2: "Go-to places",
      restaurant_selection: {
        h3: "Our selection of restaurants"
      },
      other_restaurants: {
        h3: "Other restaurants"
      },
      gourmet_addresses: {
        h3: "Our addresses for gourmets",
        cheese: {
          h4: "Cheese"
        },
        wines: {
          h4: "Wine"
        },
        bakeries: {
          h4: "Bakeries"
        },
        pastries: {
          h4: "Pastries"
        },
        caterers: {
          h4: "Caterers"
        },
        vegetables: {
          h4: "Vegetables"
        },
        flowers: {
          h4: "Florist"
        }
      },
    },
    closeActivities: {
      h2: "Close activities",
      amusement_parcs: {
        h3: "Amusement parcs"
      },
      museeums: {
        h3: "Museeums / Monuments"
      },
      sport_activities: {
        h3: "Outdoor activities"
      },
      other: {
        h3: "Others"
      }
    },
    uponLeaving: {
      h2: "Upon leaving",
      furniture: {
        h3: "Furniture"
      },
      equipment: {
        h3: "Amenities"
      },
      windows: {
        h3: "Windows and lights"
      },
      trash: {
        h3: "Trash cans"
      },
      towels: {
        h3: "Sheets and towels"
      },
      rate_us: {
        h3: "Rate us!"
      }
    },
    other_mentions: {
      h2: "Other mentions",
      video_surveillance: {
        h3: "Video surveillance"
      },
      fire_safety: {
        h3: "Fire safety notice"
      },
      remark: {
        h3: "Note"
      }
    },
    closing_words: {
      h2: "Closing words"
    },
    behavior: {
      name: "Behavior"
    },
    noise: {
      name: "Noise"
    },
    tobbaco: {
      name: "Tobbaco"
    },
    upkeep: {
      name: "Upkeep"
    },
    trash: {
      name: "Trash"
    },
    rooms: {
      name: "Rooms"
    },
    water_electricity_heating: {
      name: "Water, Electricity, Heating"
    },
    pets: {
      name: "Pets"
    },
    toilets: {
      name: "Toilets"
    },
    bbq: {
      name: "Barbecue"
    },
    insurance: {
      name: "Insurance"
    },
    fire_safety: {
      name: "Fire Safety"
    },
    leaving: {
      name: "Leaving"
    },
    jacuzzi: {
      name: "Jacuzzi"
    },
    sauna: {
      name: "Sauna"
    },
    caution_table: {
      name: "Caution table"
    },
    sort_guide: {
      name: "Local trash sorting guide"
    },
    glass_bins: {
      name: "Glass bin locations"
    },
    jacuzzi_manual: {
      name: "Jacuzzi user manual"
    }
  },
  biens: {
    pageTitle: "Gites & Spa Strasbourg",
    bien_select_text: "Please select the place you are currently staying in:",
    welcome_booklet: {
      pageTitle: "Welcome booklet"
    },
    rules_booklet: {
      pageTitle: "Internal ruleset",
      equipment_notice: "Operating manual",
      see_on_map: "View on Google Maps"
    },
    h2: {
      overview: "Overview",
      other: "In short",
      reviews: "Recognized quality",
      convinced: "Convinced ? Book online now !"
    },
    'sainte-maxime': {
      short: "80m² appartment fully furnished ideally located near the beach and the city center. It has many high quality equipment for optimal comfort.",
      presentation: {
        title: "An exceptional appartment",
        h2: {
          activities: "Recreation for all ages",
          rooms: "Spacious bedrooms",
          equipments: "Many equipments",
        },
      }
    }
  },
  partners: {
    name: "Our partners",
    discover: "Discover",
    sainte_maxime: "Very nice villas and appartments located in Strasbourg in the east of France. Spas, Saunas, Arcade game machines, Petanque, table tennis and many more all await you!"
  }
}