import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53aacde7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "menu-btn",
  id: "call-us",
  href: "tel:+33670713959"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_LocaleSwitch = _resolveComponent("LocaleSwitch")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JbButton = _resolveComponent("JbButton")!

  return (_openBlock(), _createElementBlock("nav", {
    id: "menu",
    class: _normalizeClass(_ctx.$store.getters.menuToggled ? 'open' : '')
  }, [
    _createElementVNode("div", {
      class: "close",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('closeMenu')))
    }, [
      _createVNode(_component_font_awesome_icon, { icon: "times" })
    ]),
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, [
        _createVNode(_component_LocaleSwitch)
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          to: "/",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.commit('closeMenu')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              "fixed-width": "",
              icon: "home"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('home.menu.video')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          to: "/visiter/sainte-maxime",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.commit('closeMenu')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              "fixed-width": "",
              icon: "door-open"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('home.menu.visit')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          to: "/partenaires",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$store.commit('closeMenu')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              "fixed-width": "",
              icon: "handshake"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('home.menu.partners')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_router_link, {
      class: "menu-btn",
      id: "book-btn",
      to: "/reserver",
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$store.commit('closeMenu')))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_JbButton, {
          fullwidth: "",
          "icon-left": "calendar-alt",
          color: "error",
          size: "xl"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('home.menu.availability')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("a", _hoisted_1, [
      _createVNode(_component_JbButton, {
        fullwidth: "",
        "icon-left": "phone",
        size: "l"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('words.call_us')), 1)
        ]),
        _: 1
      })
    ])
  ], 2))
}