// disable linter camelCase BS
/*eslint-disable */
export const fr = {
  home: {
    menu: {
      video: "Accueil",
      availability: "Disponibilités",
      visit: "Visiter",
      partners: "Partenaires",
      testemonies: "Témoignages",
      contact: "Contact",
    },
    video: {
      descriptions: {
        1: {
          highlight1: "Superbes villas",
          normal1: "pouvant accueillir",
          highlight2: "jusqu'à 15 personnes"
        },
        2: {
          normal1: "Un",
          highlight1: "espace SPA",
          normal2: "totalement privé",
          highlight2: "accessible 24h/24",
        },
        3: {
          normal1: "Située à seulement",
          highlight1: "5 minutes de Strasbourg",
        },
        4: {
          normal1: "Passez un agréable moment",
          highlight1: "en famille",
          normal2: "ou",
          highlight2: "entre amis",
        }
      },
      title: "Un magnifique appartement de haut standing pour vos vacances, avec piscine et salle de sport à Sainte-Maxime !",
    },
    availability: {
      title: "Disponibilités de nos biens",
      h2: "Visualisez d'un coup d'œil nos créneaux disponibles",
      popovers: {
        0: "Journée réservée",
        1: "Peut être la fin d'un séjour",
        2: "Date libre"
      },
      tooManyUsers: "Ce logement ne peut accueillir que {0} personnes au maximum",
      button: {
        book: "Réservez en ligne",
        details: "Plus de détails",
        loading: "Recherche...",
        select: "Selectionez une période...",
        error: "Logement non disponible, veuillez essayer une autre période"
      }
    },
    presentation: {
      title: "Découvrez notre airbnb",
      minStay: "La durée de séjour minimum est de deux jours sauf le dimanche soir.",
      nominStay: "Pas de durée minimum pour ce logement."
    },
    testemonies: {
      title: "L'avis de nos clients"
    },
    contact: {
      title: "Besoin d'un renseignement ?",
      name: "Vos nom et prénom",
      email: "Votre adresse e-mail",
      by_mail: {
        subTitle: "Par email",
        fill_form: "Remplissez le formulaire et nous reviendrons vers vous dans les plus brefs délais."
      },
      by_phone: {
        subTitle: "Appelez nous au",
        call: "Par téléphone",
        your_number: "Votre numéro de téléphone",
        called_back: "Ou, si vous souhaitez être rappelé, laissez nous votre numéro de téléphone et nous vous contacterons dans la journée.",
      },
      message: "Écrivez votre message ici",
      send: "Envoyer",
      sent: "Message envoyé !"
    },
    footer: {
      legal_notice: {
        name: "Mentions légales"
      },
      welcome_booklet: {
        name: "Livret d'accueil"
      },
      rules_booklet: {
        name: "Règlement intérieur"
      }
    },
    selectDate: "Dates",
    selectNumber: "Nombre de voyageurs",
    search_btn: "Rechercher"
  },
  visit: {
    title: "Visiter notre Airbnb"
  },
  cookies: {
    sentence: "Nous utilisons les cookies afin de fournir les services et fonctionnalités proposés sur notre site et afin d’améliorer l’expérience de nos utilisateurs. Les cookies sont stockés sur votre ordinateur ou sur tout autre appareil et ne seront jamais utilisés à d'autes fins.",
    button: "Je comprend et j'accepte."
  },
  words: {
    and: "et",
    for: "pour",
    bedroom: "chambre | chambres",
    beds: {
      simple: "lit simple | lits simples",
      double: "lit double | lits doubles",
      couch: "canapé-lit | canapés-lits",
    },
    person: "personne | personnes",
    call_us: "Nous appeller",
    villa: "La villa",
    apartment: "L'appartement",
    studio: "Le studio"
  },
  chapters: "Chapitres",
  titles: {
    welcome: {
      h2: "Bienvenue !"
    },
    beforeArrival: {
      h2: "Avant votre arrivée",
      howToFind: {
        h3: "Comment se rendre au logement ?",
        address: "L'adresse",
        car: "En voiture",
        train: "En train",
        bus: "En bus",
        plane: "En avion"
      },
      hours: {
        h3: "Horaires"
      }
    },
    onArrival: {
      h2: "À votre arrivée",
      whereToPark: {
        h3: "Où se garer ?"
      },
      goldenRules: {
        h3: "Nos trois règles d'or"
      },
      products: {
        h3: "Produits fournis / produits à prévoir"
      },
      payment: {
        h3: "Paiement du séjour (si réservation en direct)"
      },
      keys: {
        h3: "Remise des clés"
      }
    },
    manuals: {
      h2: "Notices d’utilisation des équipements",
      jacuzzi: {
        h3: "Jacuzzi"
      },
      sauna: {
        h3: "Sauna"
      },
      barbecue: {
        h3: "Barbecue"
      },
      wifi: {
        h3: "Réseau Wifi"
      },
      dishwasher: {
        h3: "Lave-vaisselle"
      },
      toilets: {
        h3: "Sanitaires"
      },
      sorting_trash: {
        h3: "Poubelles de tri"
      },
      glass_trash: {
        h3: "Poubelles à verre"
      }
    },
    practicalInfo: {
      h2: "Infos pratiques",
      shops: {
        h3: "Faires ses courses et acheter du carburant"
      },
      shops_sunday: {
        h3: "Le dimanche"
      },
      doctors: {
        h3: "Médecins, hopitaux, pharmacies"
      },
      other: {
        h3: "Autres"
      }
    },
    goToPlaces: {
      h2: "Bonnes adresses",
      restaurant_selection: {
        h3: "Notre sélection de restaurants"
      },
      other_restaurants: {
        h3: "Autres restaurants"
      },
      gourmet_addresses: {
        h3: "Nos adresses pour les fins gourmets",
        cheese: {
          h4: "Fromage"
        },
        wines: {
          h4: "Vin"
        },
        bakeries: {
          h4: "Boulangeries"
        },
        pastries: {
          h4: "Pâtisseries"
        },
        caterers: {
          h4: "Traiteurs"
        },
        vegetables: {
          h4: "Légumes"
        },
        flowers: {
          h4: "Fleuriste"
        }
      },
    },
    closeActivities: {
      h2: "Activités proches",
      amusement_parcs: {
        h3: "Parcs d’attraction"
      },
      museeums: {
        h3: "Musées / Monuments"
      },
      sport_activities: {
        h3: "Activités sportives"
      },
      other: {
        h3: "Autres"
      }
    },
    uponLeaving: {
      h2: "À votre départ",
      furniture: {
        h3: "Mobilier"
      },
      equipment: {
        h3: "Équipements"
      },
      windows: {
        h3: "Fenêtres et lumières"
      },
      trash: {
        h3: "Poubelles"
      },
      towels: {
        h3: "Draps & serviettes"
      },
      rate_us: {
        h3: "Notez nous !"
      }
    },
    other_mentions: {
      h2: "Autres mentions",
      video_surveillance: {
        h3: "Video-surveillance"
      },
      fire_safety: {
        h3: "Sécurité incendie"
      },
      remark: {
        h3: "Remarque"
      }
    },
    closing_words: {
      h2: "Mot de fin"
    },
    behavior: {
      name: "Comportement"
    },
    noise: {
      name: "Bruit"
    },
    tobbaco: {
      name: "Tabac"
    },
    upkeep: {
      name: "Entretien"
    },
    trash: {
      name: "Poubelles"
    },
    rooms: {
      name: "Chambres"
    },
    water_electricity_heating: {
      name: "Eau, Électricité, Chauffage"
    },
    pets: {
      name: "Animaux Domestiques"
    },
    toilets: {
      name: "Sanitaires"
    },
    bbq: {
      name: "Barbecue"
    },
    insurance: {
      name: "Assurance"
    },
    fire_safety: {
      name: "Sécurité Incendie"
    },
    leaving: {
      name: "Départ"
    },
    jacuzzi: {
      name: "Jacuzzi"
    },
    sauna: {
      name: "Sauna"
    },
    caution_table: {
      name: "Tableau de caution"
    },
    sort_guide: {
      name: "Guide de tri communal"
    },
    glass_bins: {
      name: "Emplacement des conteneurs à verre"
    },
    jacuzzi_manual: {
      name: "Manuel d’utilisation du jacuzzi"
    }
  },
  biens: {
    pageTitle: "Gites & Spa Strasbourg",
    bien_select_text: "Sélectionnez le gîte dans lequel vous vous trouvez actuellement :",
    welcome_booklet: {
      pageTitle: "Livret d'accueil"
    },
    rules_booklet: {
      pageTitle: "Règlement intérieur",
      equipment_notice: "Notices des équipements",
      see_on_map: "Voir sur Google Maps"
    },
    h2: {
      overview: "Vue d'ensemble",
      other: "En bref",
      reviews: "Une qualité reconnue",
      convinced: "Convaincus ? Reservez dès maintenant !"
    },
    'sainte-maxime': {
      short: "Appartment de 80m² meublé et idéalement situé près de la plage et du centre-ville. Possède de nombreux équipements de haute-qualité pour un confort optimal.",
      presentation: {
        title: "Une appartement d'exception",
        h2: {
          activities: "Des loisirs pour tous les âges",
          rooms: "Des chambres spacieuses",
          equipments: "De nombreux équipements",
        },
      }
    }
  },
  partners: {
    name: "Nos partenaires",
    discover: "Découvrez",
    sainte_maxime: "Très belles villas et appartements situés à Strasbourg, dans le Bas-Rhin. Un accueil haut de gamme avec Spa, Sauna, Bornes d'arcades, Pétanque, Ping-pong, etc. vous y attend !"
  }
}